

.home_page {
    text-align: center;
    margin-top: 20px;
}

.signal_table {
    /* flex: 2; */
    /* border: 1px solid red; */
    padding: 0px  30px;
    margin-bottom: 30px;
}



.strategy_table {
    /* border: 1px solid red; */
    margin-bottom: 30px;
}

.strategy_table  h2 , .signal_table h2{
    text-align: center;
    margin-bottom: 12px;
}

.strategy_table  h3 {
    text-align: center;
}
/* Signal Table */
.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

/* .styled-table tbody tr:last-of-type {
border-bottom: 2px solid #009879;
} */

.styled-table tbody tr.active-row {
font-weight: bold;
color: #009879;
}

/* End of Signal Table */

.note {
    text-align: left;
    margin-bottom: 30px; /* Adjust this if you want some spacing between the table and the note */
    margin-top: 30px;
    font-size: 0.9em; /* Adjust the font size if needed */
    font-family: 'Roboto', Arial, sans-serif;
    font-style: italic;
    color: #333; /* Adjust the color if needed */
    padding-left: 10%;
    padding-right: 10%;
}

/* Mobile Styles */
@media (max-width: 767px) {
    .note {
        padding-left: 10px;
    }
}