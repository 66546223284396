.nav-el {
    color: black
}

.me-auto {
    color: black !important;
}

Nav {
    color: black !important;
}

Nav :hover {
    color: rgb(76, 187, 23) !important;
}

Link , #basic-nav-dropdown{
    color: black !important
}

#basic-nav-dropdown :hover {
    color: rgb(76, 187, 23) !important;
}

