
  .fw-bold {
    font-weight: bold;
  }
  
  .mt-4 {
    margin-top: 1.5rem;
  }
  
  .highlight {
    color: #e63946;
    font-weight: bold;
  }
  
  ul {
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .cta {
    text-align: center;
    margin-top: 20px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #1d70b8;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .cta-button:hover {
    background-color: #145ea8;
  }
  