* {
    box-sizing: border-box;
}

.contact {
    display: flex;
    flex-direction: row;
    background-color: #FBFBFB;
;
}


.info {
    text-align: left;
}

.logo {
    display: flex;
    justify-content: center;
}

p a {
    text-decoration: none;
    color: black
}