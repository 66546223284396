*{box-sizing: border-box}

.product-title {
    margin: 20px;

}

.product-title h3 {
    color: #4CBB17
}


h3 {
    margin-top: 20px;
}

.products-list {
    display: flex;
    flex-wrap: wrap;

}

.product {
    align-content: center;
}


.product-img {
    width: 100%;
    
    margin-bottom: 0px;
    align-items: center;

}

.product-img img {
    width: 90%;
    border-radius: 5%;
}

.product-item-title {
    height: 40px;
    /* background-color: #313131; */
    /* width: 80%; */
    /* color: white; */
    margin-top: 0px;
}