* {
    list-style: none;
    text-align: start;
}

li:before {
    content: "✔";
    margin-right: 5px;
    color:  #4CBB17; 
    text-shadow: 0 0 0 #4CBB17;
    padding: 0;
  }
