* {
    box-sizing: border-box;
}


.subscription_form {
    background-image: url('./hp-form-bgr.jpg');
    background-size: cover;
    height: 70vh;
    display: flex;
    justify-content: start;
    
}

.formBox {

    background-color: white;
    border: 1px solid rgb(184, 183, 183);
    border-radius: 10px;
    height: fit-content;
    margin: auto 10%;
    padding: 20px;
    box-shadow: 3px 3px 15px 0px #00000026;

}

