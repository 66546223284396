.jumbo1 {
    display: flex;
    flex-wrap: wrap;
}

.headline {
    background-image: url('./logo2.png');
    background-repeat: no-repeat;

    background-size: contain;
    background-position: center;
    height: 450px; /* Set a fixed height for your div */
}

.headline h2{
    margin: 100px 0 40px;
}

.benefit {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 40px;
}


.item p{
    margin-top: 10px;
}

@media (max-width: 600px) {
    .jumbo1_qrcode {
      display: none;
    }
  }