h2 {
    font-family:'Archivo', sans-serif ;
    color: #4CBB17;
    font-weight: bold;
    text-align: center;
    
}

#testimonial-desktop {
    display: flex;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
.otro-blockquote{
  font-size: 0.9em;
  margin:50px auto;
  font-family: 'Archivo', sans-serif;
  color: #555555;
  padding: 2em 30px 1.2em 30px;
  text-align: justify;
  line-height:1.5;
  position: relative;
  background:#EDEDED;
  border-radius: 10px;
}

.otro-blockquote::before{
  font-family: 'Archivo', sans-serif;
  content: "\201C";
  color:rgb(76, 187, 23);
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

.otro-blockquote::after{
  content: '';
}

.otro-blockquote span{
  display:block;
  color: #4CBB17;
  font-style: normal;
  font-size: 1.2em;
  font-weight: bold;
  margin-top:1em;
  text-align: center;
}

.otro-blockquote-img {
    display: flex;
    justify-content: center;
}

.otro-blockquote-img img {
    width: 30%;
    border-radius: 100%;
}

/* mobile */
.slick-slider {
    width: 80%;
    margin: 0 auto;
  }


/* Responsive */

#testimonial-desktop {
    display: none; 
}

@media only screen and (min-width: 768px) {
    /* show the element only on screens wider than 768px (i.e., desktop) */
    #testimonial-desktop {
        display: flex;
    }
    #testimonial-mobile {
        display: none;
    }
  }