#investor_course li{
    list-style-type: disc;
    margin-left: 10px;
}

#investor_course li::before {
    content: none;
    
}

.course_detail_line {
    display: flex;
    line-height: 150px;
    margin: 0 auto;
}